<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="7" lg="6" xl="5">
			<base-form-simple ref="PasswordForm"
				formTitle="Se requiere cambiar la contraseña"
				:formLoading="loadingPasswordForm"
				@cancelButton="closePasswordModal"
				@saveButton="saveNewPassword"
			>
				<template v-slot:formContent>
					<v-container class="no-padding" fluid>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<base-text-field
									v-model="editedPassword.lastPassword"
									label="Contraseña Actual"
									hint="Al menos 8 caracteres"
									:append-icon="showPwd1 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd1 ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd1 = !showPwd1"
									:rules="[commonValRules.required, validationRules.passwordRules.counter]"
									required
								></base-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<base-text-field 
									v-model="editedPassword.newPassword"
									label="Nueva Contraseña"
									hint="Al menos 8 caracteres"
									:append-icon="showPwd2 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd2 ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd2 = !showPwd2"
									:rules="[commonValRules.required, validationRules.passwordRules.counter, validationRules.passwordRules.valid]"
									required
								></base-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<base-text-field
									v-model="editedPassword.confirmNewPassword"
									label="Confirmar Nueva Contraseña"
									hint="Al menos 8 caracteres"
									:append-icon="showPwd3 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPwd3 ? 'text' : 'password'"
									maxlength="25"
									@click:append="showPwd3 = !showPwd3"
									:rules="[commonValRules.required, validationRules.passwordRules.counter, validationRules.passwordRules.match]"
									required
								></base-text-field>
							</v-col>
						</v-row>
					</v-container>
				</template>
			</base-form-simple>
		</v-col>

		<base-loader />
	</v-row>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			showPwd1: false,
			showPwd2: false,
			showPwd3: false,
			editedPassword: {
        lastPassword: '',
				newPassword: '',
				confirmNewPassword: '',
      },
      defaultPassword: {
        lastPassword: '',
				newPassword: '',
				confirmNewPassword: '',
      },
			showPasswordModal: false,
			loadingPasswordForm: false,

			validationRules: {
				passwordRules: {
					counter: value => (value.length >= 8 && value.length <= 25) || 'No debe tener más de 25 caracteres, ni menos de 8 caracteres.',
					valid: value => (value !== this.editedPassword.lastPassword) || 'No debe ser igual a la contraseña actual.',
					match: value => (value === this.editedPassword.newPassword) || 'No coincide con la nueva contraseña.',
        },
			}
		}
	},

	methods: {
		async saveNewPassword(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingPasswordForm = true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'identifier': me.userData.identifier,
					'lastPassword': me.editedPassword.lastPassword,
					'newPassword': me.editedPassword.newPassword,
				};
				await me.getBaseResponse('api/User/ChangePassword', request).then(data => {
					if (data) {
						me.$router.push({ name:'InitialData' });
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingPasswordForm = false;
      }
		},

    closePasswordModal() {
			this.$refs.PasswordForm.reset();
			this.editedPassword = Object.assign({}, this.defaultPassword);
		},
	},
}
</script>